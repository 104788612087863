.explanation {
    display: flex;
    justify-content: center;
}

.explanation__content {
    margin-left: 32px;
    margin-right: 32px;
    margin-top: 64px;
    margin-bottom: 64px;

    background-color: rgba(255, 255, 255, 0.2);

    border-radius: 1em;
}

.explanation__content__title {
    color: #ffffff;
    margin-left: 32px;
    margin-right: 32px;
}

.explanation__content__text {
    color: #ffffff;
    margin-left: 32px;
    margin-right: 32px;
}