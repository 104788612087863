.header {
    background-color: rgba(255, 255, 255, 0.2);
    height: 5em;
    display: flex;
    align-items: center;
    min-width: 320px;
    overflow: hidden;
}

.header__logo {
    height: 4em;
    width: auto;
    margin-left: 32px;
}
.header__logo-text {
    height: 2em;
    margin-left: 32px;
}

.header__text--header-text {
    font-size: 2.5em;
    font-weight: bold;
}

.header__text {
    color: #5DB885;
}

.header__text-colon {
    color: #37123C;
}