.status {
    background-color: rgba(255, 255, 255, 0.2);
    display: flex;
    width: 100%;
    justify-content: left;
    position: relative;
    margin-top: 12px;
}

.status__content {
    margin-left: 32px;
    margin-right: 32px;
    margin-top: 32px;
    margin-bottom: 32px;
}

.status__content__title {
    color: #ffffff;
    font-size: 2em;
    font-weight: bold;
}

.status__content__description-text {
    margin-top: 16px;
    color: #ffffff;
    font-size: 1em;
}

.status__content__description-value {
    font-weight: bold;
    color: #69ff69;
}

.status__content__service-version-text {
    color: #ffffff;
    font-size: 1em;
}

.status__content__service-version-value {
    font-weight: bold;
    color: #69ff69;
}

.status__content__current-status-text {
    color: #ffffff;
    font-size: 1em;
}

.status__content__current-status-value {
    font-weight: bold;
    color: #69ff69;
}

.status--offline{
    color: red;
}