.page {
    position: absolute;
    width: 100%;
    height: 100%;

    overflow: hidden;
}

.page__gradient-green {
    background: radial-gradient(circle, rgba(51, 115, 92, 1), rgba(255, 0, 0, 0), rgba(0, 0, 200, 0));
    /* Der obige Code erstellt einen runden Verlauf von halbtransparentem Rot in der Mitte zu transparentem Rot nach außen hin */
    width: 100em;
    height: 100em;
    padding: 20px; /* Fügt Innenabstand hinzu */
    box-sizing: border-box; /* Berücksichtigt das Padding in der Gesamtbreite und -höhe */

    position: absolute;

    left: -55em;
    top: -55em;
    z-index: 10;
}

.page__gradient-blue {
    background: radial-gradient(circle, rgba(70, 44, 184, .5), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
    /* Der obige Code erstellt einen runden Verlauf von halbtransparentem Rot in der Mitte zu transparentem Rot nach außen hin */
    width: 120em;
    height: 120em;
    padding: 20px; /* Fügt Innenabstand hinzu */
    box-sizing: border-box; /* Berücksichtigt das Padding in der Gesamtbreite und -höhe */

    position: absolute;

    z-index: 12;

    right: -54em;
    top: -20em;
}

.page__gradient-purple {
    background: radial-gradient(circle, rgba(54, 28, 73, 1), rgba(255, 0, 0, 0), rgba(0, 0, 255, 0));
    /* Der obige Code erstellt einen runden Verlauf von halbtransparentem Rot in der Mitte zu transparentem Rot nach außen hin */
    width: 100em;
    height: 100em;
    padding: 20px; /* Fügt Innenabstand hinzu */
    box-sizing: border-box; /* Berücksichtigt das Padding in der Gesamtbreite und -höhe */

    position: absolute;

    z-index: 11;

    left: -50em;
    top: -10em;
}

.page__content {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 20;

    overflow: auto;
}